<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Gps Setup</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/gps-setup/create">
          <v-icon left dark>mdi-plus</v-icon>New Gps-setup
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-text-field dense v-model="search" label="Search"></v-text-field>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="GpsSetUp"
              :search="search"
              item-key="id"
              :single-select="singleSelect"
            >
              <template v-slot:item.id="{ item }">
                <v-btn color="primary" text :to="`/gps-setup/${item.id}`">
                  <v-icon color="green">mdi-eye</v-icon></v-btn
                >
              </template>

              <template v-slot:item.Active="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                  >{{ item.Active == 1 ? "Active" : "In Active" }}</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    GpsSetUp: [],
    loader: false,
    dialog: false,
    singleSelect: true,
    headers: [
      { text: "Name", value: "Name" },
      { text: "Frequency(mins)", value: "UpdtFrq" },
      { text: "Status", value: "Active" },
      { text: "Action", value: "id" },
    ],
  }),

  methods: {
    getGpsSetups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/gps-setup`)
        .then((res) => {
          self.GpsSetUp = res.ResponseData;
          console.log(res, "res");
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getGpsSetups();
  },
};
</script>